import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import * as ReduxObservable from 'redux-observable'
import * as Router from 'router'
import { map } from 'rxjs/operators'
import * as Modals from 'shared/modals'
import { SubscriptionCancellationResultModal } from '../../modals'
import { BILLING_SECTION_MODALS_KEY } from './constants'

const closeModalType: string = Modals.closeModalConstant(
  BILLING_SECTION_MODALS_KEY, SubscriptionCancellationResultModal.key)

export const closeCancelModal: ReduxObservable.Epic = (action$, state$) =>
  action$.ofType(closeModalType)
    .pipe(
      map((res) => Router.redirect(`${DashboardMenuOption.billing}`)),
    )

export const epic = closeCancelModal
