import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import * as Flash from 'components/flash'
import { Changeplanicon } from 'components/icons/changeplanicon'
import { Planicon } from 'components/icons/planicon'
import { Loading } from 'components/loading'
import { OnChangeEvent, PricingSliderComponent } from 'components/pricing-slider/component'
import { MenuComponentProps, TabMenuMessage } from 'components/tab-menu/component'
import { RasaContext } from 'context'
import * as GA from 'google-analytics'
import { sendGTMEvent } from 'google-tag-manager'
import { isEmpty } from 'lodash'
import React, {Component} from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { PlanUpdateTypes } from 'shared_server_client/constants'
import {
  BillingPlan,
  BillingPlanDetail,
  CurrentPlan,
  getProjectedContactPricing,
  isFreePlan,
  isOverContactLimit,
  isRasaPlan,
  ProductSubscription,
} from 'shared_server_client/types/billing_plan'
import * as BillingUtils from '../../../../billing/utils'
import { AjaxWrapper, HttpMethod } from '../../../../generic/ajaxWrapper'
import { isAccountOwner } from '../../../../generic/utility'
import { AccountCancelledSplash } from './cancelled'
import { ALREADY_PAID_ERROR, ALREADY_PAID_ERROR_MSG, EXCLUDE_BILLING_PLANS_IN_UI } from './constants'
import { PublishMessages } from './types'

interface AccountDetailsState {
  accountId: string,
  cardAndInvoiceMessage?: TabMenuMessage,
  communityId: string,
  currentPaymentMethod?: any,
  currentPlan?: CurrentPlan,
  hostedPaymentPageUrl: string,
  isAccountOwner: boolean,
  latestInvoice?: any,
  loading: boolean,
  loadingDialog: boolean,
  ownerEmail: string,
  plansToShow: BillingPlan[],
  productSubscription: ProductSubscription,
  showHostedPaymentPage: boolean,
  usageStats?: any,
}

export class AccountDetailsSection extends Component<MenuComponentProps, AccountDetailsState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: MenuComponentProps) {
    super(props)
    this.state = {
      accountId: null,
      communityId: null,
      hostedPaymentPageUrl: null,
      isAccountOwner: null,
      loading: true,
      loadingDialog: false,
      ownerEmail: '',
      plansToShow: [],
      productSubscription: null,
      showHostedPaymentPage: false,
      usageStats: null,
    }

    // register our instance with the container that created us
    if (this.props.menuComponent) {
      this.props.menuComponent.registerChild(this)
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ])
    .then(([activeCommunity, person]) => {
      this.setState({
      accountId: person.accountId,
      communityId: activeCommunity.communityId,
      currentPlan: activeCommunity.billingInfo.currentPlan,
      isAccountOwner: isAccountOwner(person, activeCommunity),
      ownerEmail: activeCommunity.data.owner_email,
      plansToShow: activeCommunity.billingInfo.plansToShow,
      productSubscription: activeCommunity.billingInfo.productSubscription,
      usageStats: activeCommunity.billingInfo.usageStats,
      })
      this.checkNeedUpdateCBP()
    })
  }

  protected receiveMessage(message: TabMenuMessage) {
    switch (message.code) {
      case PublishMessages.cancelSubscription:
      case PublishMessages.reactivateSubscription:
        this.setState({...this.state, loading: true}, () => {
          this.loadData()
        })
        break
      case PublishMessages.cardAndInvoiceDataLoaded: {
        // we have card and latest invoice information, from the billing tab
        // we can now load community usage stats since the latest invoice date info
        // can be used
        // no invoice info so just set state for currentpayment method and dont
        // do usage stats stuff above
        this.setState({
          ...this.state,
          cardAndInvoiceMessage: message, // save for later
          currentPaymentMethod: message.payload.currentPaymentMethod,
          latestInvoice: message.payload.latestInvoice,
        })
        this.loadData()
      }
    }
  }

  public render() {
    if ( this.isCancelled() ) {
      return <AccountCancelledSplash subscription={this.state.productSubscription}/>
    } else {
      return <div>
              {this.getModalJSX()}
              {this.getCurrentPlanMessageUsageJSX()}
              {this.getCurrentPlanJSX()}
              {this.getUpComingPlanJSX()}
              {this.state.isAccountOwner && this.getPlanListJSX()}
            </div>
    }
  }

  protected doAnnualPrice(): JSX.Element {
    if (this.state.currentPlan.code_annual && this.state.currentPlan.annual_cost > 0) {
      return <div className="settings-account-billing-annual-price">
        ${this.formatNumber(this.state.currentPlan.annual_cost, 0)} / year
      </div>
    } else {
      return null
    }
  }

  protected doCouponCode(): JSX.Element {
    if (this.state.productSubscription.coupon_code) {
      return <div className="settings-account-billing-annual-price">
        Coupon: {this.state.productSubscription.coupon_code}
      </div>
    } else {
      return null
    }
  }

  protected checkNeedUpdateCBP() {
    if (this.context.store.getState().app.params.hostedpage_id) {
      // zoho just updated something, so call the appropriate end-point to notify our server that it might need to
      // update the community billing plan to refresh from zoho's changes on the zoho side
      try {
        const url: string = AjaxWrapper.getServerUrl() +
                            `/subscription-billing/update-subscription-from-billing-system/${this.state.communityId}`
        AjaxWrapper.ajax(url, HttpMethod.PUT, {}).then((result) => {
          if (result.payload && result.payload.planUpdateType === PlanUpdateTypes.UPGRADE) {
            sendGTMEvent({
              name: PlanUpdateTypes.UPGRADE,
            })
          }
          window.location.replace(DashboardMenuOption.billing);
          this.loadData(true)
        })
        .catch((error) => {
          this.setState({
            loading: false,
          })
        })
      } catch (e) {
        this.loadData(true)
      }
    } else {
      // regular path, no zoho update, just call handleDataLoad()
      this.loadData(false)
    }
  }

  protected loadData(force = false) {
    let loadPromise
    if (force) {
      loadPromise = BillingUtils.getProductSubscription(this.context.user.activeCommunity.communityId)
      .then((result) => {
        this.setState({
          productSubscription: result.productSubscription,
          currentPlan: result.currentPlan,
          loading: false,
          plansToShow: result.plansToShow,
          usageStats: result.usageStats,
          cardAndInvoiceMessage: null, // wipe this out if it was stored earlier...
        }, () => {
          return true
        })
      })
    } else {
      loadPromise = new Promise((resolve, reject) => {
        this.setState({ loading: false }, () => resolve(true))
      })
    }

    loadPromise.then(() => {
      if (this.props.menuComponent) {
        this.props.menuComponent.publishMessage({
          code: PublishMessages.accountDataLoaded,
          payload: {
            currentPlan: this.state.currentPlan,
            productSubscription: this.state.productSubscription,
          },
          sourceElement: this,
        })
      }
    })
  }

  protected handlePlanSelect = (item: BillingPlan) => {
    // first, show the dialog with loading information so the user knows something is going on...
    this.setState({
      ...this.state,
      showHostedPaymentPage: true,
      loadingDialog: true,
    }, () => {
      // now, we've shown the dialog with a loading message so we can call the slow ass API
      // to get our URL and display the stuff
      // call the API to get a URL for a Hosted Billing Page that we can embed into an IFRAME
      // to get the user to PAY UP!
      const planCode: string = item.code_monthly || item.code_annual

      const ga4Event = GA.createCheckoutEvent(this.createCheckoutItem(item), sessionStorage)
      this.context.store.dispatch(GA.sendGa4Event(ga4Event))

      const url: string = AjaxWrapper.getServerUrl() +
              `/subscription-billing/get-billing-page/${this.state.communityId}/${planCode}`

      const payload: any = {
        contacts: getProjectedContactPricing(item, this.state.usageStats.contactCount),
      }
      AjaxWrapper.ajax(url, HttpMethod.POST, payload).then((result) => {
        if (result.code === 0) {
          this.setState({...this.state,
            showHostedPaymentPage: true,
            hostedPaymentPageUrl: result.payload.url,
            loadingDialog: false,
          })
        } else {
          this.setState({
            loadingDialog: false,
            hostedPaymentPageUrl: null,
          })
          // eslint-disable-next-line no-console
          console.log('Failed Response', result)
        }
      }).catch((err) => {
        if (err.response && err.response.error && err.response.error.includes(ALREADY_PAID_ERROR)) {
          this.setState({
            hostedPaymentPageUrl: null,
            loadingDialog: false,
            showHostedPaymentPage: false,
        }, () => {
            this.context.store.dispatch(Flash.showFlashMessage(ALREADY_PAID_ERROR_MSG))
          })
        } else {
          this.setState({
            hostedPaymentPageUrl: null,
            loadingDialog: false,
          })
        }
        // eslint-disable-next-line no-console
        console.log('Error Response', err)
      })
    })
  }

  protected toggle(e?: any) {
    this.setState({...this.state, showHostedPaymentPage: !this.state.showHostedPaymentPage})
  }

  protected formatNumber(value: number, decimals: number): string {
    if (value) {
      return value.toLocaleString(
        undefined, // leave undefined to use the browser's locale,
                   // or use a string like 'en-US' to override it.
        { minimumFractionDigits: decimals },
      )
    } else {
      return '0'
    }
  }

  protected isCancelled() {
    return this.state.productSubscription && this.state.productSubscription.is_cancelled
  }

  protected getCurrentPlanMessageUsageJSX(): JSX.Element {
    if (!this.state.usageStats || !this.state.currentPlan) {
      return <div className="settings-account-billing-loading-wrapper">
              <Loading size="32"></Loading> Loading Usage Information...
            </div>
    } else if (isFreePlan(this.state.currentPlan) ) {
      return null
    } else {
      if (this.state.currentPlan.includedMessage !== -1) {
        return this.messageBasedUsageJSX()
      } else if (this.state.usageStats.currentPlanIncludedContacts !== -1) {
        return this.contactBasedUsageJSX()
      } else {
        return null
      }
    }
  }

  protected getCurrentPlanJSX(): JSX.Element {
    return <div className="settings-account-billing-current-plan-wrapper">
        {this.state.loading ?
          <div className="settings-account-billing-loading-wrapper">
            <Loading size="32" />
            Loading Plan Information...
          </div>
        :  this.getPlanJSX()
        }
    </div>
  }

  protected getUpComingPlanJSX(): JSX.Element {
    return <div className="settings-account-billing-current-plan-wrapper">
      {this.state.loading ?
        <div className="settings-account-billing-loading-wrapper">
          <Loading size="32" />
          Loading Plan Information...
        </div>
      :   this.getUpcomingPlanJSX()
      }
    </div>
  }

  protected getPlanListJSX(): JSX.Element {
    return <div>
      {this.state.loading === false &&
        this.state.currentPlan &&
        this.state.productSubscription.status !== 'canceled' &&
        this.state.plansToShow.length > 0 && (
        <div className="settings-account-billing-plan-list-wrapper">
            <div>
              <Changeplanicon/>
              <div className="settings-account-billing-plan-header">
                <div className="settings-account-billing-plan-name">Change your plan</div>
                <div className="settings-account-billing-plan-description">
                  <span>
                    You are currently on the {this.state.currentPlan.name} plan with
                    {this.state.currentPlan.code_monthly ? ' monthly ' : ' annual ' }
                    billing. Select a different plan below if you would like to make a change.
                  </span>
                </div>
              </div>
              <div className="billingwrap">
              <div className="planwrap">
                {this.state.plansToShow.filter((item: BillingPlan) =>
                  EXCLUDE_BILLING_PLANS_IN_UI.indexOf(item.name) === -1)
                  .map((item: BillingPlan, index: number) =>
                  <div key={`billing-plan-${index}`}
                    className={'settings-account-billing-single-plan-wrapper ' + item.name.replace(' ', '-')}>
                    <div className="settings-account-billing-plan-title">
                      {item.name}
                    </div>
                    <div className="settings-account-billing-plan-price">
                      <PricingSliderComponent
                        currentPlan={this.state.currentPlan}
                        plan={item}
                        usage={this.state.usageStats}
                        onChange={this.sliderChanged}
                      />
                    </div>
                    {item.id === this.state.currentPlan.id && (
                      <div className="settings-account-billing-plan-button
                                      settings-account-billing-plan-current-plan-button">Current Plan</div>
                    )}
                    {item.id !== this.state.currentPlan.id && (
                      <div onClick={(e) => this.handlePlanSelect(item)}>
                        <div className="settings-account-billing-plan-button clickable-item">
                          <a className="anchor-link">Select Plan</a>
                        </div>
                      </div>
                    )}
                    <div className="settings-account-billing-plan-details">
                      <ul>
                        {item.details.filter((detailItem: BillingPlanDetail) =>
                          detailItem.display && !isEmpty(detailItem.billing_plan_detail_description))
                        .map((detailItem: BillingPlanDetail, detailIndex: number) =>
                          <li key={`billing-plan-detail-item-${detailIndex}`}
                          className="settings-account-billing-plan-detail-item">
                            {detailItem.billing_plan_detail_description}
                          </li>)
                        }
                      </ul>
                    </div>
                  </div> ) }
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  }

  protected  getModalJSX(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.showHostedPaymentPage} toggle={() => this.toggle()}
               className="settings-account-billing-modal"
               size="lg"
               fade={false}
               centered={true} >
          <ModalHeader toggle={() => this.toggle()}>Switch Plan</ModalHeader>
          <ModalBody className="settings-account-billing-modal-body">
            {this.state.loadingDialog ?
              <div className="settings-account-billing-loading-wrapper"><Loading size="32"></Loading></div>
            : this.state.hostedPaymentPageUrl ?
              <iframe
                className="settings-account-billing-iframe"
                src={this.state.hostedPaymentPageUrl}
              />
            : <div>
                There was a problem processing your request.  Please refresh your page and try again.
              </div>
            }
          </ModalBody>
        </Modal>
      </div>
    )
  }

  private getPlanJSX() {
    if (isRasaPlan(this.state.currentPlan)) {
      return this.getRasaPlanJSX()
    } else {
      return this.getPaidPlanJSX()
    }
  }

  private sliderChanged = (e: OnChangeEvent) => {
    // Update here in case anything needs to be done on slider change
  }

  private createCheckoutItem = (item: BillingPlan): GA.CheckoutItem => {
    return {
      id: item.id,
      name: item.name,
      price: item.monthly_cost,
    }
  }

  private getRasaPlanJSX(): JSX.Element {
    return <div>
    {this.state.currentPlan && (
      <div>
        <Planicon/>
        <div className="settings-account-billing-plan-header">
          <div className="settings-account-billing-plan-label">CURRENT PLAN</div>
          <div className="settings-account-billing-plan-name">{this.state.currentPlan.name}</div>
        </div>
      </div>
    )}
    <div className="clearfix"></div>
    </div>
  }

  private getPaidPlanJSX(): JSX.Element {
    return <div>
        {this.state.currentPlan && (
          <div>
            <Planicon/>
            <div className="settings-account-billing-plan-header">
              <div className="settings-account-billing-plan-label">CURRENT PLAN</div>
              <div className="settings-account-billing-plan-name">{this.state.currentPlan.name}</div>
            </div>
            {(this.state.currentPlan.annual_cost > 0 || this.state.currentPlan.monthly_cost > 0) && (
              <div className="settings-account-billing-plan-price settings-account-billing-current-plan-price">
                <div className="plan-price">
                  <div>
                    <span>
                      ${this.state.currentPlan.code_monthly ?
                        this.formatNumber(this.state.currentPlan.monthly_cost, 0) :
                        this.formatNumber(this.state.currentPlan.annual_cost / 12, 0)}
                    </span>
                    <span className="settings-account-billing-freqency-label">/ month</span>
                  </div>
                  {this.doAnnualPrice()}
                  {this.doCouponCode()}
                </div>
              </div>
            )}
          </div>
      )}
      <div className="clearfix">
      </div>
    </div>
  }

  private getUpcomingPlanJSX() {
    const upcomingTier = getProjectedContactPricing(this.state.currentPlan, this.state.usageStats.contactCount)
    if (upcomingTier.id > 0 && this.state.usageStats.projectedContactPricing.maximum < this.state.usageStats.currentPlanIncludedContacts) {
      const currentTier = getProjectedContactPricing(this.state.currentPlan, this.state.usageStats.currentPlanIncludedContacts)
      const cost = this.state.currentPlan.code_monthly ?
        (this.state.currentPlan.monthly_cost - currentTier.cost) + upcomingTier.cost :
        (this.state.currentPlan.annual_cost - currentTier.cost) + upcomingTier.cost / 12
      return <div>
        <div>
          <Planicon/>
          <div className="settings-account-billing-plan-header">
            <div className="settings-account-billing-plan-label">UPCOMING PLAN</div>
            <div className="settings-account-billing-plan-name">{this.state.currentPlan.name}</div>
            <div className="settings-account-billing-plan-description">
              <span>
                Based on your current contact count, your plan will change on {this.getNextPaymentDate()}.
              </span>
            </div>
          </div>
          {(this.state.currentPlan.annual_cost > 0 || this.state.currentPlan.monthly_cost > 0) && (
            <div className="settings-account-billing-plan-price settings-account-billing-current-plan-price">
              <div className="plan-price">
                <div>
                    <span>
                      ${this.state.currentPlan.code_monthly ?
                      this.formatNumber(cost, 0) :
                      this.formatNumber(cost / 12, 0)}
                    </span>
                  <span className="settings-account-billing-freqency-label">/ month</span>
                </div>
                {this.doAnnualPrice()}
                {this.doCouponCode()}
              </div>
            </div>
          )}
        </div>
        <div className="clearfix"></div>
      </div>
    }
  }

  private messageBasedUsageJSX(): JSX.Element {
    return (
          <div className="settings-account-billing-usage-wrapper">
            <div className="settings-account-billing-plan-header first">
              <div className="usagewrap">
                <div className="settings-account-billing-plan-usage-message">
                  Usage for this billing period (actual and projected)
                </div>
                <div className="settings-account-billing-plan-usage-gauge"
                    style={{ // override border radius on right side if we're going above 100%
                      borderTopRightRadius: this.state.usageStats.projectedOveragePercent > 0 ? '0px' : '',
                      borderBottomRightRadius: this.state.usageStats.projectedOveragePercent > 0 ? '0px' : '',
                    }}
                >
                  <div className="settings-account-billing-plan-usage-so-far"
                      title={`${this.formatNumber(this.state.usageStats.numActualMessages, 0)} used so far this billing period`}
                      style={{width: this.state.usageStats.projectedOveragePercent > 0 ? 0 : `${this.state.usageStats.usagePercent}%`}}>
                      </div>
                  <div className="settings-account-billing-plan-projected"
                      title={`${this.formatNumber(this.state.usageStats.numProjectedMessages, 0)} projected through end of billing period for a total of ${
                        this.formatNumber(this.state.usageStats.numProjectedTotalMessages, 0)}`}
                      style={{
                        width: this.state.usageStats.projectedOveragePercent > 0 ? 450 : `${this.state.usageStats.cappedProjectedPercent}%`,
                        borderRadius: this.state.usageStats.projectedOveragePercent > 0 ? '5px' : '',
                        backgroundColor: this.state.usageStats.projectedOveragePercent > 0 ? 'firebrick' : '',
                      }}>
                  </div>
                </div>
                <div className="clearfix">
                  <span className="settings-account-billing-plan-usage-data first">
                    {this.formatNumber(this.state.usageStats.numActualMessages, 0)} sent
                  </span>
                {this.state.usageStats.currentPlanIncludedMessages < 0 ? null :
                  <div>
                    <span className="settings-account-billing-plan-usage-data-inner-label"> of </span>
                    <span className="settings-account-billing-plan-usage-data">
                      {this.formatNumber(this.state.usageStats.currentPlanIncludedMessages, 0)} available
                    </span>
                  </div>
                }
                </div>
              </div>
              <div className="usagewrap2">
                <div>
                  <span className="settings-account-billing-plan-projected-data first">
                    {this.formatNumber(this.state.usageStats.numProjectedMessages, 0)
                    } messages forecasted</span> by end of billing period through <b>
                      {this.state.usageStats.numProjectedIssues} issue{this.state.usageStats.numProjectedIssues === 0 ||
                      this.state.usageStats.numProjectedIssues > 1 ? 's' : ''}
                  </b>
                </div>
                <div>
                  <span className="settings-account-billing-plan-total-projected-data">
                  {this.formatNumber(this.state.usageStats.numProjectedTotalMessages, 0)} total messages projected
                  </span>
                  &nbsp;by end of billing period
                </div>
              </div>
            </div>
            <div className="clearfix"/>
          </div>
        )
  }

  private contactBasedUsageJSX(): JSX.Element {
    const {usageStats} = this.state
    return (
      <div className="settings-account-billing-usage-wrapper">
        <div className="settings-account-billing-plan-header first">
          <div className="usagewrap">
            <div className="settings-account-billing-plan-usage-message">
              {`You currently have ${this.formatNumber(usageStats.contactCount, 0)} contacts on your
              ${this.formatNumber(usageStats.currentPlanIncludedContacts, 0)} contact plan.`}
            </div>
            <div className="settings-account-billing-plan-contact-usage-container"
                title={`${this.formatNumber(usageStats.currentPlanIncludedContacts, 0)}`}
                style={{width: '100%'}}>
              <div className="settings-account-billing-plan-contact-usage-so-far"
                title={`${this.formatNumber(usageStats.contactCount, 0)} contacts currently`}
                style={{
                width: isOverContactLimit(usageStats)
                  ? `${(usageStats.currentPlanIncludedContacts / usageStats.contactCount) * 100}%`
                  : `${(usageStats.contactCount / usageStats.currentPlanIncludedContacts) * 100}%`,
                }}>
              </div>
              {!!isOverContactLimit(usageStats) &&
              <div className="settings-account-billing-plan-contact-overusage"
                title={`${this.formatNumber(usageStats.contactCount, 0)} contacts over limit currently`}
                style={{width: `${Math.abs((usageStats.currentPlanIncludedContacts / usageStats.contactCount) - 1) * 100}%`}}>
              </div>}
            </div>
          </div>
        </div>
        <div className="clearfix"/>
      </div>
    )
  }

  private getNextPaymentDate() {
    const latestInvoiceDate = this.state.latestInvoice ? new Date(this.state.latestInvoice.invoiceDate) : new Date(this.state.productSubscription.created)
    if (this.state.currentPlan.code_monthly) {
      return this.addMonths(latestInvoiceDate, 1).toLocaleDateString()
    } else {
      return this.addYears(latestInvoiceDate, 1).toLocaleDateString()
    }
  }

  private addMonths(theDate: Date, numMonths: number): Date {
    theDate.setMonth(theDate.getMonth() + numMonths)
    return theDate
  }

  private addYears(theDate: Date, numYears: number): Date {
    theDate.setFullYear(theDate.getFullYear() + numYears)
    return theDate
  }
}
