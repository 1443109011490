import { DropdownOption } from 'components/dropdown/component';

export const AnyAllPlaceholder: DropdownOption[] = [
    {key: '1', value: 'any', description: 'any'},
    {key: '2', value: 'all', description: 'all'},
  ]

export const IsIsNotPlaceholder: DropdownOption[] = [
  {key: '1', value: 'is', description: 'is'},
  {key: '2', value: 'is not', description: 'is not'},
]

export const CityStatePlaceholder: DropdownOption[] = [
  {key: '1', value: 'City', description: 'City'},
  {key: '2', value: 'State', description: 'State'},
]
