import { AmericanExpressIcon } from 'components/icons/americanexpress'
import { DiscoverIcon } from 'components/icons/discover'
import { MastercardIcon } from 'components/icons/mastercard'
import { VisaIcon } from 'components/icons/visa'
import { Loading } from 'components/loading'
import { MenuComponentProps, TabMenuMessage } from 'components/tab-menu/component'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import React, {Component} from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { ProductSubscription } from 'shared_server_client/types/billing_plan'
import { SubscriptionCancellationResultModal } from '../../modals'
import { CancelSubscription } from './cancel'
import { AccountCancelledSplash } from './cancelled'
import { BILLING_SECTION_MODALS_KEY, SUBSCRIPTION_STATUS_ALLOW_TO_CANCEL } from './constants'
import { PublishMessages } from './types'
import { zohoSubscriptionTime } from '../../../../shared_server_client/dates'

interface BillingSectionState {
  accountId: number,
  communityId: string,
  currentPlan: any,
  currentPaymentMethod: any,
  data: any,
  error: boolean,
  hostedPaymentPageUrl: string,
  loading: boolean,
  loadingDialog: boolean,
  productSubscription: ProductSubscription,
  schedule: any,
  showCancelDialog: boolean,
  showHostedPaymentPage: boolean,
  showReactivateDialog: boolean,
  usageStats?: any,
}

type BillingSectionProps = GenericRedux.AllComponentPropsWithModal<any> & MenuComponentProps

export class BillingSectionComponent extends Component<BillingSectionProps, BillingSectionState> {
  public static contextType = RasaContext

  constructor(props: BillingSectionProps) {
    super(props)
    this.state = {
      accountId: null,
      communityId: null,
      loading: true,
      loadingDialog: false,
      data: null,
      error: false,
      showHostedPaymentPage: false,
      hostedPaymentPageUrl: null,
      currentPaymentMethod: null,
      showCancelDialog: false,
      showReactivateDialog: false,
      productSubscription: null,
      currentPlan: null,
      schedule: null,
    }

    if (this.props.menuComponent) {
      this.props.menuComponent.registerChild(this)
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        accountId: person.accountId,
        communityId: activeCommunity.communityId,
        schedule: activeCommunity.communityInfo.data.schedule[0],
      }, () => this.loadData())
    })
  }

  private loadData(): Promise<any> {
    const url: string = AjaxWrapper.getServerUrl() +
                        `/subscription-billing/get-customer-credit-cards-and-invoices/${this.state.communityId}`
    return AjaxWrapper.ajax(url, HttpMethod.GET, {})
        .then((newData) => {
          this.setState({
            loading: false,
            data: newData.payload,
          })

          if (this.props.menuComponent) {
            this.props.menuComponent.publishMessage({
              code: PublishMessages.cardAndInvoiceDataLoaded,
              payload: newData.payload,
              sourceElement: this,
            })
          }
        })
        .catch((error) => {
          this.setState({
            error: true,
            loading: false,
          })
        })
  }

  public render() {
    if ( this.isCancelled() ) {
      return <AccountCancelledSplash subscription={this.state.productSubscription}/>
    } else {
      return <div className="settings-billing-wrapper">
                <SubscriptionCancellationResultModal
                  data={this.props.modals}
                  closeModal={this.closeModal}
                  closeButtonText="OK"
                />
                {this.getUpdateCardModalJSX()}
                {this.state.data && this.getCancelModalJSX()}
                {this.currentPaymentMethodJSX()}
                {this.updateCardJSX()}
                {this.cancelSubscriptionJSX()}
              </div>
    }
  }

  private closeModal = (data: any) => {
    return this.props.closeModal(data)
  }

  public receiveMessage(message: TabMenuMessage) {
    switch (message.code) {
      case PublishMessages.accountDataLoaded:
        this.setState({
          currentPlan: message.payload.currentPlan || this.state.currentPlan,
          productSubscription: message.payload.productSubscription || this.state.productSubscription,
        })
        break
      case PublishMessages.usageStatsLoaded:
        this.setState({
          usageStats: message.payload,
        })
        break
      case PublishMessages.showReactivate:
        this.toggleReactivateDialog()
        break
    }
  }

  private cancelSubscriptionJSX(): JSX.Element {
    if ( this.state.productSubscription ) {
      if ( SUBSCRIPTION_STATUS_ALLOW_TO_CANCEL.includes(this.state.productSubscription.status) ) {
        return <div className="settings-billing-cancel-subscription">
            <div className="settings-billing-add-new-method-button clickable-item"
                  onClick={() => this.openCancelDialog()}>
              Cancel Subscription
            </div>
          </div>
      }
    }
    return null
  }

  private updateCardJSX(): JSX.Element {
    return <div>{ this.state.productSubscription &&
                  this.state.productSubscription.status !== 'canceled' && (
                    <div className="settings-billing-saved-payment-methods">
                      <div className="settings-billing-add-new-method-button clickable-item"
                           onClick={() => this.updatePaymentMethod()}>
                        Update Card
                      </div>
                    </div>
                  )}
            </div>
  }
  private getCardTypeImage(paymentMethod: any) {
    if (paymentMethod && paymentMethod.cardType) {
      /////// HANK - Paul - We need to implement this properly to switch on the card image based on the below
      switch (paymentMethod.cardType.trim().toLowerCase()) {
        case 'visa':
          return <VisaIcon/>
        case 'mastercard':
          return <MastercardIcon/>
        case 'amex':
          return <AmericanExpressIcon/>
        case 'discover':
          return <DiscoverIcon/>
      }
    }
    return <MastercardIcon/>
  }

  private formatCardTypeName(paymentMethod: string) {
    const tmp = paymentMethod ? paymentMethod.trim().toLowerCase() : null
    return tmp ? tmp.substring(0, 1).toUpperCase() + tmp.substring(1, tmp.length) : null
  }

  private currentPaymentMethodJSX(): JSX.Element {
    return <div>{ this.state.productSubscription &&
                  this.state.productSubscription.status !== 'canceled' && (
        <div className="settings-billing-current-payment-method">
          {this.state.data && this.state.loading === false && (
            <div>
              <div className="settings-billing-current-payment-left-side">
                <div className="settings-billing-payment-method-image-wrapper">
                  {this.getCardTypeImage(this.state.data.currentPaymentMethod)}
                </div>
                {this.state.data.currentPaymentMethod && (
                  <div className="settings-billing-current-method-wrapper">
                    <div className="settings-billing-current-method-label">
                      CURRENT PAYMENT METHOD
                    </div>
                    <div className="settings-billing-current-payment-title">
                      {this.formatCardTypeName(this.state.data.currentPaymentMethod.cardType)
                      } ending in {this.state.data.currentPaymentMethod.lastFourDigits}
                    </div>
                    <div className="settings-billing-current-payment-title">
                      Expires on {`${this.state.data.currentPaymentMethod.expirationMonth}/${this.state.data.currentPaymentMethod.expirationYear}`}
                    </div>
                  </div>
                )}
              </div>
              {this.state.data.latestInvoice && (
                <div className="settings-billing-last-payment-wrapper">
                  <div className="settings-billing-last-payment-date">
                    Last Payment: {this.getLastPaymentDate()}
                  </div>
                  <div className="settings-billing-next-payment-date">
                    Next Payment Scheduled: {this.getNextPaymentDate()}
                  </div>
                </div>
              )}
            </div>
        )}
      </div>)}
    </div>
  }

  private getLastPaymentDate() {
    if (this.state.data.latestInvoice) {
      return zohoSubscriptionTime(this.state.data.latestInvoice.invoiceDate)
    } else {
      return 'New Subscription'
    }
  }

  private getNextPaymentDate() {
    const latestInvoiceDate = this.state.data.latestInvoice ? new Date(this.state.data.latestInvoice.invoiceDate) : this.state.productSubscription.created
    if (this.state.currentPlan && this.state.currentPlan.code_monthly) {
      return this.addMonths(latestInvoiceDate, 1).toLocaleDateString()
    } else {
      return this.addYears(latestInvoiceDate, 1).toLocaleDateString()
    }
  }

  private doLoadAndNotify(messageCode: string, payload: any) {
    this.setState({loading: true}, () => {
      this.loadData()
      this.notify(messageCode, payload)
    })
  }

  private notify(messageCode: string, payload: any) {
    if (this.props.menuComponent) {
      this.props.menuComponent.publishMessage({
        code: messageCode,
        payload,
        sourceElement: this,
      })
    }
  }

  private cancelSubscription(cancelData: any) {
    // show loading status again as this might take a couple of seconds to switch to free
    this.setState({loadingDialog: true}, () => {
      const url: string = AjaxWrapper.getServerUrl() +
                          `/subscription-billing/cancel-subscription/${this.context.user.activeCommunity.communityId}`
      Promise.all([
        AjaxWrapper.ajax(url, HttpMethod.POST, { body: cancelData }),
      ])
        .then(([updateSubscriptionResult]) => {
          this.closeCancelDialog()
          if (updateSubscriptionResult.code === 0) {
            // worked
            this.props.openModal(SubscriptionCancellationResultModal.key, updateSubscriptionResult.payload)
            this.doLoadAndNotify(PublishMessages.cancelSubscription, updateSubscriptionResult.payload)
          } else {
            // eslint-disable-next-line no-console
            console.log(updateSubscriptionResult)
          }
        })
        .catch((err) => {
          this.closeCancelDialog()
          alert('Unable to complete the schedule cancellation.')
          // eslint-disable-next-line no-console
          console.log(err)
        })
    })
  }

  private updatePaymentMethod() {
    // show the dialog first so user knows we are cooking up something
    this.setState({
      showHostedPaymentPage: true,
      loadingDialog: true,
    }, () => {
      // now, we have shown our dialog with loading message/spinner... so
      // get a hosted billing page URL to change credit card...
      const url: string = AjaxWrapper.getServerUrl() +
                          `/subscription-billing/get-credit-card-page/${this.state.communityId}`

      AjaxWrapper.ajax(url, HttpMethod.GET, null).then((result) => {
          if (result.code === 0) {
            this.setState({
              showHostedPaymentPage: true,
              hostedPaymentPageUrl: result.payload.url,
              showCancelDialog: false,
              loadingDialog: false,
            }, () => {
              this.notify(PublishMessages.paymentMethodChanged, {})
            })
          } else {
            // eslint-disable-next-line no-console
            console.log(result)
          }
      })
    })
  }

  private toggleHostedPaymentPage() {
    this.setState({
      showHostedPaymentPage: !this.state.showHostedPaymentPage,
    })
  }

  private openCancelDialog() {
    this.setState({
      showCancelDialog: true,
    })
  }

  private closeCancelDialog() {
    this.setState({
      loadingDialog: false,
      showCancelDialog: false,
    })
  }

  private toggleReactivateDialog() {
    this.setState({
      showReactivateDialog: !this.state.showReactivateDialog,
    })
  }

  private getUpdateCardModalJSX(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.showHostedPaymentPage} toggle={() => this.toggleHostedPaymentPage()}
               className="settings-account-billing-modal"
               size="lg"
               fade={false}
               centered={true} >
          <ModalHeader toggle={() => this.toggleHostedPaymentPage()}>Payment Information</ModalHeader>
          <ModalBody className="settings-account-billing-modal-body">
            {this.state.loadingDialog && (
              <div><Loading size="32"></Loading></div>
            )}
            {this.state.loadingDialog === false && (
                <iframe
                className="settings-account-billing-iframe"
                src={this.state.hostedPaymentPageUrl}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    )
  }

  private getCancelModalJSX(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.showCancelDialog} toggle={() => this.closeCancelDialog()}
               className="settings-account-billing-modal"
               size="lg"
               fade={false}
               centered={true} >
          <ModalHeader toggle={() => this.closeCancelDialog()}>Payment Information</ModalHeader>
          <ModalBody className="settings-account-billing-modal-body">
            {this.state.loadingDialog
            ?
              <div><Loading size="32"></Loading></div>
            : <CancelSubscription
                onCancelConfirmed={(data) => this.cancelSubscription(data)}
                onClose={() => this.closeCancelDialog()}
                endDate={this.getNextPaymentDate()}/>
            }
          </ModalBody>
        </Modal>
      </div>
    )
  }

  private addMonths(theDate: Date, numMonths: number): Date {
    theDate.setMonth(theDate.getMonth() + numMonths)
    return theDate
  }

  private addYears(theDate: Date, numYears: number): Date {
    theDate.setFullYear(theDate.getFullYear() + numYears)
    return theDate
  }

  private isCancelled() {
    return this.state.productSubscription && this.state.productSubscription.is_cancelled
  }

}

export const BillingSection = GenericRedux.registerNewComponentWithModals<any>(
  BillingSectionComponent,
  BILLING_SECTION_MODALS_KEY,
  [SubscriptionCancellationResultModal.key],
  {},
)
