import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { MenuComponentProps } from 'components/tab-menu/component'
import { RasaContext } from 'context'
import * as Globals from 'generic/globals'
import React from 'react'
import { AccountDetailsSection } from '../account'
import { AccountCancelledSplash } from '../cancelled'
import './_styles.scss'

export class MobileAccountDetails extends AccountDetailsSection {
  public static contextType = RasaContext;

  constructor(props: MenuComponentProps) {
    super(props);
  }

  public render() {
    return (
      <div className="mobile-billing-page">
      { this.isCancelled()
      ? <AccountCancelledSplash subscription={this.state.productSubscription}/>
      : <div>
          {this.getModalJSX()}
          {this.getCurrentPlanMessageUsageJSX()}
          {this.getCurrentPlanJSX()}
          {this.state.isAccountOwner && this.getPlanListJSX()}
        </div>
      }
        <div className="mobile-logout-button-section">
          {this.getLogoutButton()}
        </div>
      </div>
    )
  }

  private getLogoutButton(): JSX.Element {
    return(
      <div className="logout-button-mobile">
      <a className="mobile-logout" href={(DashboardMenuOption.logOut)}>
        <img className="dashboard-popup-menu-small-icon" src={Globals.LOGOUT_IMG}></img>
        Log out
      </a>
    </div>
    )
  }
}
