import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { MenuItem, TabMenu } from 'components/tab-menu/component'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { isAccountOwner } from 'generic/utility'
import React, {Component} from 'react'
import { BillingSystemCode, ProductSubscription } from 'shared_server_client/types/billing_plan'
import '../../styles.css'
import { AccountDetailsSection } from './account'
import { BillingSection } from './billing'
import { AccountCancelledSplash } from './cancelled'
import { InvoiceEmails } from './invoice-emails'
import { PaymentHistorySection } from './payment-history'

type AccountBillingProp = any
type SettingsAccountBillingProps = GenericRedux.DatasetComponentProps<AccountBillingProp>

const AccountTabOnlyMenu: MenuItem[] = [
  {
    component: AccountDetailsSection,
    key: 'account',
    name: 'Account',
    preLoad: true,
  },
]

const AllBillingTabMenus: MenuItem[] = [
  {
    component: AccountDetailsSection,
    key: 'account',
    name: 'Account',
    preLoad: true,
  },
  {
    component: BillingSection,
    key: 'billing',
    name: 'Billing',
    preLoad: true,
  },
  {
    component: PaymentHistorySection,
    key: 'payment_history',
    name: 'Payment History',
    preLoad: true,
  },
  {
    component: InvoiceEmails,
    key: 'invoice_emails',
    name: 'Invoice Emails',
    preLoad: true,
  },
]

interface BillingState {
  billingSystemCode: string,
  communityId: string,
  currentSection: string,
  isAccountOwner: boolean,
  isLoading: boolean,
  productSubscription: ProductSubscription,
}

export class AccountBilling extends Component<SettingsAccountBillingProps, BillingState> {
  public static contextType = RasaContext;

  constructor(props: SettingsAccountBillingProps) {
    super(props);
    this.state = {
      billingSystemCode: null,
      currentSection: 'account',
      communityId: null,
      isAccountOwner: false,
      isLoading: true,
      productSubscription: null,
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        billingSystemCode: (activeCommunity.billingInfo.currentPlan || {}).billing_system_code,
        communityId: activeCommunity.communityId,
        isAccountOwner: isAccountOwner(person, activeCommunity),
        isLoading: false,
        productSubscription: activeCommunity.billingInfo.productSubscription,
      })
    })
  }

  public onShow() {
    // do stuff here to refresh when we are shown - this means we were hidden previously and may need
    // to refresh
  }

  public render() {
    return (
      <div className="settings-account-and-billing">
        <HeaderComponent
          title={'Settings'}
          subTitle={'Account & Billing'}
        />
      { this.state.isLoading
        ? <Loading size="64"></Loading>
        : this.isCancelled() ?
          <AccountCancelledSplash subscription={this.state.productSubscription}/>
          :
          <div className="section-body">
            {(this.state.currentSection === 'account' && !this.state.isAccountOwner) &&
            <div className="upgrade-restriction-notification">
              Only the account creator can upgrade your account
            </div>}
            <div>
              <TabMenu menus={this.getBillingTabs()} selectedItemKey={this.state.currentSection}/>
            </div>
          </div>
      }
      </div>
    )
  }

  private getBillingTabs() {
    return this.state.billingSystemCode === BillingSystemCode.ZOHO ? AllBillingTabMenus : AccountTabOnlyMenu
  }

  private isCancelled() {
    return this.state.productSubscription && this.state.productSubscription.is_cancelled
  }

}
