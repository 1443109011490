import { GridColumn as Column } from '@progress/kendo-react-grid'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import { Button } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import '../../styles.css'
import { AddInvoiceEmailModal } from './add-invoice-email-modal'

type InvoiceEmailsProps =
  GenericRedux.AllComponentPropsWithModal<any>

interface InvoiceEmailsState {
  communityId: string,
  data: any,
  forceReload: boolean,
}

class InvoiceEmailsComponent extends RasaReactComponent<InvoiceEmailsProps, InvoiceEmailsState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore

  constructor(props: InvoiceEmailsProps) {
    super(props, 'zohoContacts');
    this.state = {
      communityId: null,
      data: null,
      forceReload: false,
      isDirty: false,
      isLoading: true,
      isSaving: false,
    }
    this.doSave = this.doSave.bind(this)
  }
  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      this.selectCommunity(activeCommunity.communityId)
    })
  }

  public render() {
    return <div className="invoice-emails">
              <div className="list">
                <RasaDataGrid
                  // Rasisa Properties for entity, datasetName etc
                  entityName=""
                  datasetName="zohoContacts"
                  editDefaultState={true}
                  forceReload={this.state.forceReload}
                  // events
                  onDataReceived={this.dataReceived}
                  sortable={false}
                  pageable={false}
                  data={[]}>
                  <Column field="email" title="Email"/>
                  <Column field="first_name" title="First Name"/>
                  <Column field="last_name" title="Last Name"/>
                </RasaDataGrid>
              </div>
              <div className="add-action">
                <Button className="add-button add-newsletter"
                  onClick={() => this.props.openModal(AddInvoiceEmailModal.key, null)}>
                  + Add Email
                </Button>
              </div>
              <AddInvoiceEmailModal
                contactList={this.state.data}
                data={this.props.modals}
                closeModal={this.props.closeModal}
                saveModal={this.props.saveModal}
                onChange={this.props.propertiesChanged} onSave={this.doSave} />
            </div>
  }
  protected dataReceived = (data) => {
    if (data.data) {
      this.setState({
        data: data.data,
      });
    }
  }
  private doSave = () => {
    return this.saveRecord(null).then(() => {
      this.setState({forceReload: true}, () => this.setState({forceReload: false}));
      this.props.closeModal(AddInvoiceEmailModal.key)
    })
  }
}
export const InvoiceEmails = GenericRedux.registerNewComponentWithModals<any>(
  InvoiceEmailsComponent,
  'invoice_emails',
  [AddInvoiceEmailModal.key],
  {},
)
