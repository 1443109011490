import { GridColumn as Column } from '@progress/kendo-react-grid'
import { DateTimeCell } from 'components/content-pool/kendocells';
import {DashboardMenuOption} from 'components/dashboard-menu/constants'
import { SegmentationIcon } from 'components/icons/segmentation';
import { RasaContext } from 'context'
import React, {Component} from 'react'
import {Button} from 'reactstrap'
import { EditableDropdownCell, EditCellProps } from '../../generic/editCell'
import * as GenericRedux from '../../generic/genericRedux'
import { RasaDataGrid } from '../../generic/rasaDataGrid'
import '../subscriber-grid/styles.css'

declare const DASHBOARD_ENV: string

type Segment = any

type CommunitySegmentsProps = GenericRedux.DatasetContainerComponentProps<Segment>

class CommunitySegmentsComponent extends Component<CommunitySegmentsProps> {
  public static contextType = RasaContext;
  constructor(props: CommunitySegmentsProps) {
    super(props)

    this.state = {
      isDirty: false,
      isSaving: false,
      isLoading: false,
      communityId: null,
      subscriberType: null,
    }
  }

  public render() {
    if (DASHBOARD_ENV === 'development') {
    return <div className="segmentation-page">
      <div className="header-wrapper">
          <div className="column1">
            <h4 className="header-title">Segments</h4>
            <SegmentationIcon svgwidth={64.4} svgheight={45.5}/>
            <p className="header-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
            ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex
            </p>
          </div>
          <div className="column2">
            <Button outline className="button2" onClick={() => this.props.push(`${DashboardMenuOption.contacts}/segments/detail`) }>
              Create a Segment
            </Button>
          </div>
        </div>
        <RasaDataGrid
            entityName="communitySegment"
            datasetName="segments"
            editDefaultState={true}
            onRowClick={(e) => this.editSegment(e.dataItem)}
            onDataReceived={this.props.datasetLoaded}
            sortable={true}
            pageable={false}
            data={this.props.container.data || []}>

          <Column field="name" title="Name" editable={false} />
          <Column field="operator" title="Type" editable={false} />
          <Column field="condition_count" title="Condition Count" editable={false} />
          <Column field="created" title="Created" editable={false} cell={DateTimeCell}/>
          <Column field="updated" title="Last Activity" editable={false} cell={DateTimeCell}/>
          <Column cell={this.SegmentEditCell} width={50}/>

        </RasaDataGrid>
    </div>
    } else {
      return null
    }
  }

  private SegmentEditCell = (props: EditCellProps) => {
    return <EditableDropdownCell {...props} onEdit={this.editSegment} canEdit={true}/>
  }

  private editSegment = (s: Segment) => {
    this.props.push(`${DashboardMenuOption.contacts}/segments/detail?id=${s.id}`)
  }
}

export const ConnectedCommunitySegmentsComponent = GenericRedux.registerNewDatasetContainerComponent(
  CommunitySegmentsComponent,
  'community_segments')
