import * as React from 'react'
import { ProductSubscription } from 'shared_server_client/types/billing_plan'

interface Props {
  subscription: ProductSubscription
}

export class AccountCancelledSplash extends React.Component<Props> {

  public render() {
    if ( !this.props.subscription.is_cancelled ) {
      return null
    } else {
      const endDate: Date = new Date(this.props.subscription.end_date)
      return <div className="settings-account-billing-pending-expiration">
        <span>
          <p>
            &#128562; You have canceled your subscription.  Access to your
            data will end on {endDate.toLocaleDateString()}.
          </p>
          <p>
            Please contact <a href="mailto:support@rasa.io?subject=Re-Subscribe">Customer Support</a> if
            you decided to use our platform again.
          </p>
        </span>
      </div>
    }
  }
}
