import { HeaderComponent } from 'components/header/component'
import { Loading, SIZES } from 'components/loading'
import { RasaContext } from 'context'
import { differenceInSeconds, parseISO } from 'date-fns'
import { BaseClientEntity, RecordIdType } from 'generic/baseClientEntity'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import '../styles.css'
import * as Types from '../types'
import { Profile } from './profile'

type AccountSettingsProps = GenericRedux.AllComponentProps<Types.FullProfile>

interface AccountSettingsState {
  activeCommunity: any,
  changes: any,
  isProfileLoading: boolean,
  person: any,
}

class AccountSettingsComponent extends RasaReactComponent<AccountSettingsProps, AccountSettingsState> {
  public static contextType = RasaContext

  constructor(props: AccountSettingsProps) {
    super(props, 'userAndAccount', {
      activeCommunity: {},
      changes: {},
      isProfileLoading: true,
      person: {},
    })
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity, role}) => {
      this.setState({
        activeCommunity,
        communityId: activeCommunity.communityId,
        person,
      })
      return this.loadRecord(activeCommunity.communityId, activeCommunity.communityAccountId).then(() => {
        return this.setState({isProfileLoading: false})
      })
    })
  }

  public getLastEmailTimePassed = (lastSent: string) => {
    const sent = new Date(parseISO(lastSent))
    const now = new Date()
    const lastEmailTime = differenceInSeconds(now, sent)
    //return pending still true if 6hrs within last time email sent
    return lastEmailTime < 21600
  }

  public saveRecord(recordId: RecordIdType): Promise<any> {
    return super.saveRecord(recordId)
                .then((result: any) => {
                  this.setState({
                    changes: {},
                  })
                  return result
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.log('Error: ', err)
                  this.setState({ isSaving: false })
                })
  }

  public loadRecord(communityId: string, recordId: RecordIdType): Promise<boolean> {
    this.setState({
      isDirty: false,
      changes: {},
    })
    const accountData = this.state.activeCommunity.billingInfo.productSubscription
    this.updateComponentState( {
      account_address_1: accountData.account_address_1,
      account_address_2: accountData.account_address_2,
      account_city: accountData.account_city,
      account_country: accountData.account_country,
      account_id: accountData.account_id,
      account_phone_1: accountData.account_phone_1,
      account_phone_2: accountData.account_phone_2,
      account_post_code: accountData.account_post_code,
      account_state: accountData.account_state,
      company_name: accountData.company_name,
      options: accountData.account_options,
    })
    return Promise.resolve(true)
  }

  public mapPropsToEntityObject(entityObject: BaseClientEntity): void {
    Object.keys(this.state.changes).forEach((key: string) => {
      entityObject.data[key] = this.state.changes[key]
    })
    entityObject.data.account_id = this.props.data.account_id
  }

  public propertyChanged(field: string, value: string) {
    super.propertyChanged(field, value)
    this.setState({
      changes: {
        ...this.state.changes,
        [field]: value,
      },
    })
  }

  public render() {
    return (
      <div className="settings-wrapper">
        {this.state.isProfileLoading ? <Loading size="64"/> :
        <div className="menuline1">
          <div className="header-container">
            <HeaderComponent
              title={'Settings'}
              subTitle={'Account'}
            />
            <div className={`save-button ${this.isDirty && !this.isSaving ? 'clickable-item save-button-enabled' : 'save-button-disabled'}`}
                  onClick={(e) => this.isDirty && this.saveRecord(this.state.communityId)}>
              {this.isSaving ? <Loading size={SIZES.MEDIUM}/> : 'Update Settings'}
            </div>
          </div>

          <Profile profile={this.props.data}
                   communityId={this.state.activeCommunity.communityId}
                   onChange={(field, value) => this.propertyChanged(field, value)}
                   changes={this.state.changes}/>
          <div className="borderline2"></div>
          <div className="clearfix "></div>
        </div>
        }
      </div>
    )
  }
}
export const AccountSettings = GenericRedux.registerNewNestedComponent<Types.FullProfile>(
  AccountSettingsComponent, 'account_settings')
