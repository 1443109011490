import { RasaContext } from 'context'
import { isEmpty } from 'lodash'
import React, {Component} from 'react'

interface CancelProps {
  onClose?: any,
  onCancelConfirmed?: any,
  endDate?: string
}

interface CancelState {
  productSubscriptionId: string,
  loading: boolean,
  error: string,
  data: any,
  confirmedChecked: boolean,
  changeReasonId: string,
  changeReasonText: string,
  changeReasonComments: string,
}

export interface BillingChangeReason {
    text: string,
    id: string
}
export const changeReasons: BillingChangeReason[] = [
    {   text: 'Please select a cancellation reason', id: 'select' },
    {   text: 'Too Expensive', id: 'too_expensive' },
    {   text: 'Lacking Functionality', id: 'lacking_functionality' },
    {   text: 'Found Alternative', id: 'found_alternative' },
    {   text: 'Other', id: 'other' },
]

export class CancelSubscription extends Component<CancelProps, CancelState> {
  public static contextType = RasaContext;

  constructor(props: CancelProps) {
    super(props);
    this.state = {
      productSubscriptionId: null,
      loading: true,
      error: '',
      data: null,
      confirmedChecked: false,
      changeReasonComments: null,
      changeReasonId: 'select',
      changeReasonText: null,
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.setState({
        ...this.state,
        productSubscriptionId: activeCommunity.productSubscriptionId,
      })
      this.context.entityMetadata.getEntityObject('productSubscription',
                         activeCommunity.communityId,
                         activeCommunity.productSubscriptionId).then((ps) => {
          // got the communitybiling plan info
          this.setState({
            loading: false,
            data: ps.data,
          })
      })
    })
  }

  public render() {
    return (
      <div className="settings-billing-cancel-wrapper">
        {this.state.loading === false && (
            <div>
                <div className="settings-billing-cancel-header-message-background">
                    <div className="settings-billing-cancel-header-message">
                        Are you sure you want to cancel? &#128551;
                        <br/>
                        Your subscription doesn't expire until <b>{this.props.endDate}</b>.
                        <br/>
                        If you cancel, your subscription will be set to not renew.
                        <br/>
                        You'll continue to have access to rasa.io until <b>{this.props.endDate}</b>.
                    </div>
                </div>
                <div className="settings-billing-cancel-item">
                    Reason for cancelling
                    <div>
                        <select
                            onChange={(e) => this.handleReasonChanged(e) }
                            placeholder="Select the reason you're cancelling"
                            className={`settings-billing-cancel-reason ${isEmpty(this.state.changeReasonId) || this.state.changeReasonId === 'select' ? 'cancel-required' : ''}`}>
                            {changeReasons.map((item, i) => <option value={item.id} key={i}>{item.text}</option> )}
                        </select>
                    </div>
                </div>
                <div className="settings-billing-cancel-item">
                    Tell us more
                    <textarea
                            onChange={(e) => this.handleCancelTextChanged(e)}
                            className={`settings-billing-cancel-comments ${isEmpty(this.state.changeReasonComments) ? 'cancel-required' : ''}`}
                            placeholder="How can we improve our service?">
                        {this.state.changeReasonComments}
                    </textarea>
                </div>
                <div className="settings-billing-cancel-item">
                    <input  type="checkbox"
                            className="settings-billing-cancel-confirm-checkbox"
                            style={{display: 'inline'}}
                            onChange={(e) => this.handleConfirmedClicked(e)} >
                    </input>
                    <span className="settings-billing-cancel-confirm-text">
                        I understand that I will lose all my data after expiration
                    </span>
                    <div className="clearfix"></div>
                </div>
                <div className="settings-billing-cancel-item">
                    <div className={`settings-billing-cancel-cancelbutton ${this.validate() ? 'clickable-item' : 'settings-billing-cancel-cancelbutton-disabled'}`}
                        onClick={() => this.handleCancelConfirmed()}>
                        I'd like to cancel
                    </div>
                    <div className="settings-billing-cancel-nevermind clickable-item"
                        onClick={() => {
                            if (this.props.onClose) {
                                this.props.onClose()
                            }
                        }
                    }>
                        Nevermind, I don't want to cancel
                    </div>
                </div>
            </div>
        )}
      </div>
    )
  }

  private handleCancelTextChanged(e) {
    this.setState({
        ...this.state,
        changeReasonComments: e.currentTarget.value,
    })
  }

  private validate(): boolean {
    return  this.state.confirmedChecked &&
            this.state.changeReasonId &&
            this.state.changeReasonId !== 'select' &&
            this.state.changeReasonComments &&
            this.state.changeReasonComments.length > 0
  }
  private handleCancelConfirmed() {
    if (this.validate()) {
        // get here, means we qualify to confirm the cancelation...
        if (this.props.onCancelConfirmed) {
            this.props.onCancelConfirmed({
              changeReasonId: this.state.changeReasonId,
              changeReasonText: this.state.changeReasonText,
              changeReasonComments: this.state.changeReasonComments,
            })
        }
    } else {
        // validation error needed as the user must hit the confirmed check box
        // and other values... for now do nothing, let em figure it out
    }
  }

  private handleConfirmedClicked(e) {
    this.setState({confirmedChecked: e.currentTarget.checked})
  }
  private handleReasonChanged(e) {
    const reason: BillingChangeReason = changeReasons.find((item) => item.id === e.currentTarget.value)
    this.setState({
      changeReasonId: e.currentTarget.value,
      changeReasonText: reason ? reason.text : null,
    })
  }
}
