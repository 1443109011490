import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as Utils from 'generic/utility'
import * as React from 'react'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import * as Router from 'router'
import * as Modals from 'shared/modals'
import '../../styles.css'
interface AddInvoiceEmailState extends Modals.ModalComponentState {
  email: string
  errorMessage: string,
  firstName: string,
  lastName: string,
}

interface AddInvoiceEmailProps extends Modals.ModalComponentProps {
  contactList: any[],
  onSave: any,
  onChange: any,
  push: any,
}

export class AddInvoiceEmailModalComponent extends
Modals.GenericModalComponent<AddInvoiceEmailProps, AddInvoiceEmailState> {
  public static contextType = RasaContext
  public static key: string = 'addInvoiceEmail'
  public static defaultProps = {
    closeButtonText: 'Cancel',
    saveText: 'Create',
    secondAction: Modals.ModalActionType.BUTTON,
    title: 'Add Email',
  }
  constructor(props: AddInvoiceEmailProps) {
    super(props, AddInvoiceEmailModalComponent.key, 'Add Email')
    this.state = {
      email: '',
      errorMessage: '',
      firstName: '',
      lastName: '',
      isSaving: false,
    }
  }

  protected renderChildren(data: any) {
    return (
      <div className="add-invoice-email-modal-body">
        {this.state.isSaving
        ? <Loading size="64"/>
        : <div>
            <div className="section">
              <h6>First Name</h6>
              <Input
                value={this.state.firstName}
                placeholder="Enter first name..."
                onChange={(e) => {
                  e.preventDefault()
                  this.setState({ firstName: e.target.value })
                  this.props.onChange({
                    firstName: e.target.value,
                  })
                }}
              />
            </div>
            <div className="section">
              <h6>Last Name</h6>
              <Input
                value={this.state.lastName}
                placeholder="Enter last name..."
                onChange={(e) => {
                  e.preventDefault()
                  this.setState({ lastName: e.target.value })
                  this.props.onChange({
                    lastName: e.target.value,
                  })
                }}
              />
            </div>
            <div className="section">
              <h6>Email</h6>
              <Input
                value={this.state.email}
                placeholder="Enter email..."
                onChange={(e) => {
                  e.preventDefault()
                  this.setState({ email: e.target.value })
                  this.props.onChange({
                    email: e.target.value,
                  })
                }}
              />
            </div>
            <div className="error-message">
              {this.state.errorMessage}
            </div>
          </div>
        }
      </div>
    )
  }
  protected saveDisabled(data: any): boolean {
    if (this.state.firstName && this.state.lastName && this.state.email && Utils.validateEmail(this.state.email)
      && this.props.contactList.filter((x) => x.email === this.state.email).length === 0) {
      return false
    }
    return true
  }
  protected close(data: any) {
    this.setState({
      email: '',
      firstName: '',
      lastName: '',
    })
    this.props.closeModal(AddInvoiceEmailModal.key)
  }
  protected doSave(data: any) {
    this.setState({errorMessage: ''})
    return this.props.onSave()
    .then(() => {
      this.setState({
        email: '',
        firstName: '',
        isSaving: false,
        lastName: '',
      })
    })
    .catch((ex) => {
      this.setState({errorMessage: (ex.response || {}).message || 'Oops something went wrong!'})
      throw ex // re-throw. Let it handle by modal component.
    })
  }
}

export const AddInvoiceEmailModal = connect(
  null,
  {
    push: Router.push,
  },
)(AddInvoiceEmailModalComponent)
