import {CommunitiesList} from 'components/communities/component'
import { RasaContext } from 'context'
import React, {Component} from 'react'

export class NewslettersComponent extends Component {

  public static contextType = RasaContext
  constructor(props: any) {
    super(props)
  }

  public render() {
    return <CommunitiesList/>
  }
}
