import { sizedUrl } from 'app/utils'
import { Upload } from 'components/icons/upload2'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { isNil } from 'lodash'
import * as React from 'react'
import Dropzone from 'react-dropzone'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { IMAGE } from 'shared_server_client/constants'

import '../styles.css'
import { TextBox } from '../textbox'
import * as Types from '../types'
import { ToggleOn } from 'components/icons/toggleon'
import { ToggleOff } from 'components/icons/toggleoff'
import { isTruthy } from 'generic/utility'

interface ProfileProps extends Types.ProfileProps {
  communityId: string,
}

interface ProfileState {
  isAdminUser: boolean,
  isInternal: boolean,
}

export class Profile extends React.Component<ProfileProps, ProfileState> {
  public static contextType = RasaContext
  constructor(props: ProfileProps) {
    super(props)
    this.state = {
      isAdminUser: false,
      isInternal: false,
    }
    this.toggleInternalStatus = this.toggleInternalStatus.bind(this)
  }

  public componentDidMount = () => {
    SharedStore.instance(this.context).getValue(SharedKeys.role)
    .then((role) => {
      const accountOptions = JSON.parse(this.props.profile.options || '{}') || {}
      if (accountOptions) {
        this.setState({
          isInternal: isTruthy(accountOptions.is_internal),
        })
      }
      this.setState({
        isAdminUser: role === Roles.super_admin,
      })
    })
  }

  public render() {
    return <div>
      <div className="field-left">
        {this.createTextBox('Account Name', 'company_name')}
      </div>
      <div className="field-right">
        {this.createTextBox('PHONE NUMBER', 'account_phone_1')}
      </div>
      <div className="clearfix"></div>
      <div className="field-left">
        {this.createTextBox('ACCOUNT ADDRESS', 'account_address_1')}
      </div>
      <div className="field-right">
        {this.createTextBox('CITY', 'account_city')}
      </div>
      <div className="clearfix"></div>
      <div className="field-left">
        {this.createTextBox('STATE', 'account_state')}
      </div>
      <div className="field-right">
        {this.createTextBox('COUNTRY', 'account_country')}
      </div>
      <div className="field-right">
        {this.createTextBox('ZIP CODE', 'account_post_code')}
      </div>
      {this.state.isAdminUser && <div>
      <div className="clearfix"></div>
      <div className="field-left">
        <span className="toggle-label">Internal</span>
        <button className="toggle-button"
          onClick={this.toggleInternalStatus}
          style={{background: 'none', border: 'none', outline: 'none'}}>
          {this.state.isInternal ? <ToggleOn/> : <ToggleOff/> }
        </button>
      </div>
      <div className="clearfix"></div>
      <div className="settingsheader1">Dashboard Whitelabel Logo</div>
      <div className="clearfix"></div>
      <div className="field-left">
        <div className="email-brand-image-container">
          <Dropzone onDrop={(acceptedFiles) => this.uploadImage(acceptedFiles[0])}
            accept="image/*">
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()} className="lined-box clickable-item">
                  <input {...getInputProps()} />
                  <div className="image-or-placeholder-wrapper">
                    {this.props.profile.whitelabel_logo_url ?
                    <img src={sizedUrl(this.props.profile.whitelabel_logo_url, 150, 300, false)}/> :
                    <div className="placeholder-wrapper">
                      <div className="upload-wrapper">
                        <div className="upload"><Upload/></div>
                      </div>
                      <p className="row1-text">Choose your file</p>
                      <p className="row2-text">You may drag and drop your file as well.</p>
                      <p className="row3-text">Upload only PNG, JPG.</p>
                    </div>}
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
      </div>}
      <div className="clearfix"></div>
    </div>
  }

  private toggleInternalStatus = () => {
    this.setState({
      isInternal: !this.state.isInternal,
    }, () => {
      const accountOptions = {
        ...JSON.parse(this.props.profile.options || '{}'),
        is_internal: this.state.isInternal
      }
      this.props.onChange('options', JSON.stringify(accountOptions))
    })
  }

  private createTextBox(displayName: string, fieldName: string) {
    return <TextBox name={displayName}
                    dirty={!isNil(this.props.changes[fieldName])}
                    field={fieldName}
                    value={this.props.profile[fieldName]}
                    onChange={this.props.onChange}/>
  }

  private uploadImage(image: any) {
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.props.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage) => {
        this.props.onChange('whitelabel_logo_url', hostedImage.url)
      })
  }
}
