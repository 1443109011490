import { DropdownComponent} from 'components/dropdown/component'
import {Loading} from 'components/loading'
import { RasaContext } from 'context'
import { RecordIdType } from 'generic/baseClientEntity'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent';
import React from 'react'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row} from 'reactstrap'
import { AnyAllPlaceholder, CityStatePlaceholder, IsIsNotPlaceholder } from './constants'

declare const DASHBOARD_ENV: string

type Segment = any

interface SegmentUpsertProps extends GenericRedux.AllComponentProps<Segment> {
  segmentId: string,
  onLoad: any,
  push: any,
  isOpen: boolean,
}

interface SegmentUpsertState {
  isDirty: boolean,
  isLoading: boolean,
  isSaving: boolean,
  isEditing: boolean,
  communityId: RecordIdType,
  conditions: any[]
  segmentFieldValue: string,
  segmentId: string,
  segmentName: string,
}

class SegmentUpsertComponent extends RasaReactComponent<SegmentUpsertProps, SegmentUpsertState> {

  public static contextType = RasaContext

  constructor(props: SegmentUpsertProps) {
    super(props, 'communitySegment')

    this.state = {
      isDirty: false,
      isLoading: false,
      isSaving: false,
      isEditing: false,
      communityId: null,
      conditions: [],
      segmentFieldValue: '',
      segmentId: null,
      segmentName: '',
    }
  }

  public componentDidMount() {
    const segmentId: string = this.context.store.getState().app.params.id || null
    this.loadOnRouting(segmentId, '/contacts/segments/detail', true)
    this.setState({segmentId})
  }

  public render = () => {
    if (DASHBOARD_ENV === 'development') {
      return this.state.isSaving ?
        <div className="create-segment-page">
          <div className="bouncing-balls">
            <Loading size="64"/>
          </div>
        </div> :
        <div className="create-segment-page">
          <div className="header-flex">
            {this.state.segmentId ?
            <div>
              <h2 className="header-text">Edit Segment</h2>
              <p className="header-paragraph">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor <br/>
              invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et <br/>
              justo duo dolores et ea</p>
            </div>
            :
            <div>
              <h2 className="header-text">Create a New Segment</h2>
              <p className="header-paragraph">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor <br/>
              invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et <br/>
              justo duo dolores et ea</p>
            </div>}
          </div>
          <div className="contact-form">
          <Form>
            <Row form>
                <FormGroup>
                  <Label for="segment-name">Segment Name</Label>
                  <Input type="text"
                          value={this.props.data.name}
                          onChange={(e) => {
                            e.preventDefault()
                            this.propertyChanged('name', e.target.value)
                          }}
                          id="segment-name"
                          placeholder="Enter name of segment here" />
                </FormGroup>
            </Row>
            <Row form>
                <FormGroup>
                  <Label for="segment-name" className="conditions-label">Contacts match
                    <div id="segment-conditions">
                      <DropdownComponent  data={AnyAllPlaceholder}
                                          selected={this.props.data.operator ?
                                                    this.props.data.operator.key :
                                                    AnyAllPlaceholder[0].key}
                                          onChange={(e) => this.propertyChanged('operator', e.selected)}/>
                    </div>
                    of the following conditions:
                    <span className="add-condition"
                          onClick={() => this.props.data.conditions ?
                                   this.propertyChanged('conditions', this.props.data.conditions.concat({})) :
                                   this.propertyChanged('conditions', [{}])}>
                      + Add
                    </span>
                  </Label>
                 {this.props.data.conditions ? this.props.data.conditions.map((c, i) => {
                   // eslint-disable-next-line @typescript-eslint/unbound-method
                   return <ConditionComponent {...this.props} propertyChanged={this.propertyChanged}
                                       key={i}
                                       index={i}
                                       conditionProps={c}
                                       changeCondition={this.changeCondition}
                                       />
                 }) : null}
                </FormGroup>
            </Row>
          </Form>
          </div>
            <Button disabled={this.isDisabled()}
                    className="save-button"
                    onClick={this.saveSegment}>
              {this.state.segmentId ? 'Update Segment' : 'Create Segment'}
            </Button>
        </div>
  } else {
    return null
  }
}

  private isDisabled = () => {
    const truthArray = []
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.props.data.name ? truthArray.push(true) : truthArray.push(false)
    if (this.props.data.conditions) {
      const allIncludeKey = this.props.data.conditions.every((c) => c.value && c.value.length > 0)
      truthArray.push(allIncludeKey)
    }
    if (this.isDirty && truthArray.every((c) => c)) {
      return false
    } else {
      return true
    }
  }

  private saveSegment = () => {
    const segmentId: string = this.context.store.getState().app.params.id || null
    const operatorDefault = this.props.data.operator ?
                            this.props.data.operator.value :
                            AnyAllPlaceholder[0].value
    if (this.props.data.conditions && this.props.data.conditions.length) {
      const conditionsCopy  = this.props.data.conditions.slice()
      conditionsCopy.forEach((c) => {
        if (!c.operator) {
          c.operator = IsIsNotPlaceholder[0].value
        }
        if (!c.key) {
          c.key = CityStatePlaceholder[0].value
        }
      })
      this.propertyChanged('conditions',  conditionsCopy)
    }
    this.propertyChanged('operator',  operatorDefault)
    this.saveRecord(segmentId).then(() => this.props.push('/contacts/segments'))
  }

  private changeCondition = (i: number, property: string, value: any) => {

    if (property === 'operator' || property === 'key') {
      const conditionsCopy = this.props.data.conditions.slice()
      const current = conditionsCopy[i]
      current[property] = value.value
      conditionsCopy[i] = current
      this.propertyChanged('conditions', conditionsCopy)
    } else {

      const conditionsCopy = this.props.data.conditions.slice()
      conditionsCopy[i][property] = value
      this.propertyChanged('conditions', conditionsCopy)
    }
  }

}

export const ConditionComponent = (props: any) =>
  <Row form>
    <Col md={4}>
      <div id="segment-fields">
        <DropdownComponent data={CityStatePlaceholder}
                           selected={props.conditionProps.key ?
                                     CityStatePlaceholder[CityStatePlaceholder
                                       .map((c) => c.value)
                                       .indexOf(props.conditionProps.key)].key :
                                     CityStatePlaceholder[0].key}
                           onChange={(e) => props.changeCondition(props.index, 'key', e.selected)}/>
      </div>
    </Col>
    <Col md={3}>
      <div id="segment-field-condition">
        <DropdownComponent data={IsIsNotPlaceholder}
                           selected={props.conditionProps.operator ?
                                     IsIsNotPlaceholder[IsIsNotPlaceholder
                                      .map((c) => c.value)
                                      .indexOf(props.conditionProps.operator)].key :
                                     IsIsNotPlaceholder[0].key}
                           onChange={(e) => props.changeCondition(props.index, 'operator', e.selected)} />
      </div>
    </Col>
    <Col md={5}>
      <Input type="text"
            id="segment-field-value"
            value={props.conditionProps.value || ''}
            onChange={(e) => {
                e.preventDefault()
                props.changeCondition(props.index, 'value', e.target.value)
              }}
              placeholder="Enter value here" />
    </Col>
  </Row>

export const SegmentUpsertConnectedComponent = GenericRedux.registerNewNestedComponent(
  SegmentUpsertComponent ,
  'segment_details', {})
